import React from "react";

import Constants from "../constants";

const RecipeMetadata = ({ recipe }) => {
  const ingredients = recipe.data.Ingredients.childMarkdownRemark.html.match(
    /<li>.+?<\/li>/g
  );
  const ingredientsList = [];

  if (ingredients) {
    for (let index = 0; index < ingredients.length; index++) {
      const ingredient = ingredients[index];
      const insideLi = ingredient.match(/<li>(.*)<\/li>/);
      if (insideLi[1]) {
        ingredientsList.push(
          <meta itemProp="recipeIngredient" key={index} content={insideLi[1]} />
        );
      }
    }
  }

  return (
    <React.Fragment>
      <meta itemProp="prepTime" content={`PT${recipe.data.PrepTime}M`} />
      <meta itemProp="cookTime" content={`PT${recipe.data.CookTime}M`} />
      <meta itemProp="datePublished" content={recipe.data.PublishDate} />
      <meta itemProp="recipeYield" content={recipe.data.Yield} />
      <meta itemProp="keywords" content={recipe.data.Keywords} />
      <span
        itemProp="author"
        itemScope={true}
        itemType="https://schema.org/Person"
      >
        <meta itemProp="name" content={recipe.data.Author} />
      </span>
      <span
        itemScope={true}
        itemType="https://schema.org/AggregateRating"
        itemProp="aggregateRating"
      >
        <meta itemProp="ratingValue" content={recipe.data.Rating} />
        <meta itemProp="ratingCount" content={recipe.data.NumberOfRatings} />
      </span>
      <meta itemProp="recipeCategory" content={recipe.data.Category} />
      <meta
        itemProp="recipeInstructions"
        content={recipe.data.Notes.childMarkdownRemark.rawMarkdownBody}
      />
      <meta
        key="recipeDescription"
        itemProp="description"
        content={recipe.data.Intro.childMarkdownRemark.rawMarkdownBody}
      />
      <meta key="recipeCuisine" itemProp="recipeCuisine" content="American" />
      {recipe.data.Photos && recipe.data.Photos.localFiles.length > 0 && (
        <meta
          key="recipeImage"
          itemProp="image"
          content={
            Constants.baseUrl +
            recipe.data.Photos.localFiles[0].childImageSharpMobile.fluid.src
          }
        />
      )}
      {ingredientsList.length > 0 &&
        ingredientsList.map(ingredient => ingredient)}
    </React.Fragment>
  );
};

export default RecipeMetadata;
